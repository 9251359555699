import React from 'react';
import { HOMEPAGE_RATINGS } from '../../constants/homepage';
import Achievement from '@components/achievements/Achievement';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

const Achievements = ({ layoutV3 }: { layoutV3?: boolean }) => {
  return (
    <section
      className={ivclass(
        layoutV3
          ? 'xl:iv-pt-150 lg:iv-pt-150 iv-pt-100 sm:iv-pt-50'
          : 'xl:iv-mt-150 iv-mt-150 sm:iv-mt-75',
        'lg:iv-px-[21px] md:iv-px-25',
        'xl:iv-mb-[-15px] lg:iv-mb-[-15px] md:iv-mb-[-28px]',
      )}
    >
      <div
        className={ivclass(
          'iv-grid',
          'iv-grid-cols-12',
          'md:iv-flex sm:iv-flex iv-flex-wrap iv-justify-center',
          'sm:iv-px-10 sm:iv-pt-2',
        )}
      >
        {HOMEPAGE_RATINGS.map((item, index) => (
          <div
            key={index}
            className={ivclass(
              'iv-col-span-2',
              index === 0 && 'xl:iv-col-start-3 lg:iv-col-start-3',
              'iv-text-center',
              'sm:iv-w-1/2',
            )}
          >
            <Achievement
              firstRow={item.firstRow}
              secondRow={item.secondRow}
              thirdRow={item.thirdRow}
              CTAUrl={item.CTAUrl}
              isLast={index === HOMEPAGE_RATINGS.length - 1}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Achievements;
