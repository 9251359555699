import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import useTranslation from 'next-translate/useTranslation';

import Stars from '@components/stars';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';

interface image {
  url: string;
  width: number;
  height: number;
}

interface AchievementProps {
  firstRow: { text?: string; image?: image };
  secondRow: {
    text?: string;
    rating?: { number: number; color: string; maskColor: string };
  };
  thirdRow: { text?: { boldText: string; plainText: string }; image?: image };
  CTAUrl?: string;
  isLast?: boolean;
}

const AchievementContent = (props: AchievementProps) => {
  const { firstRow, secondRow, thirdRow } = props;

  const { t } = useTranslation('home');

  return (
    <React.Fragment>
      {firstRow.image && (
        <div
          className={ivclass(
            'iv-m-heading-4xl',
            'iv-max-w-[108px]',
            'iv-mx-auto xl:iv-mb-6 lg:iv-mb-10 md:iv-mb-8 sm:iv-mb-7',
            'lg:iv-px-9 md:iv-px-9 sm:iv-px-[19px]',
          )}
        >
          <ImageComponent
            elementype="native"
            src={firstRow.image.url}
            alt="company"
            loading="lazy"
            width={firstRow.image.width}
            height={firstRow.image.height}
          />
        </div>
      )}
      {firstRow.text && (
        <div
          className={ivclass(
            'iv-pb-10 md:iv-pb-8 sm:iv-pb-8',
            'xl:iv-text-heading-xl iv-text-heading-m sm:iv-text-heading-2xs',
            'xl:iv-m-heading-xl iv-m-heading-2xl sm:iv-m-heading-2xs',
            'iv-font-extrabold',
          )}
        >
          {t(`ratings.${firstRow.text}`)}
        </div>
      )}

      {secondRow.text && (
        <div
          className={ivclass(
            'iv-pb-10 iv-pt-9 lg:iv-py-8',
            'iv-text-body-2xl sm:iv-text-body-m',
            'iv-m-heading-2xl sm:iv-m-heading-m',
            'iv-font-semibold',
          )}
        >
          {t(`ratings.${secondRow.text}`)}
        </div>
      )}
      {secondRow.rating && (
        <div className="xl:iv-my-10 lg:iv-my-7 md:iv-my-7 sm:iv-my-7">
          <Stars
            count={secondRow.rating.number}
            color={secondRow.rating.color}
            maskColor={secondRow.rating.maskColor}
            ratingClassName="iv-achievement-ratings"
          />
        </div>
      )}

      {thirdRow.image && (
        <div
          className={ivclass(
            'iv-m-heading-3xs',
            'iv-mx-auto',
            'xl:iv-pt-10 lg:iv-pt-[14px] iv-pt-8',
            'lg:iv-px-5 md:iv-px-5 sm:iv-px-7',
          )}
          style={{ maxWidth: thirdRow.image.width }}
        >
          <ImageComponent
            elementype="native"
            src={thirdRow.image.url}
            alt="image"
            loading="lazy"
            width={thirdRow.image.width}
            height={thirdRow.image.height}
          />
        </div>
      )}
      {thirdRow.text && (
        <div
          className={ivclass(
            'iv-my-4 sm:iv-my-3',
            'iv-text-heading-3xs sm:iv-text-heading-3xs',
            'iv-m-heading-3xs sm:iv-m-heading-3xs',
            'iv-font-bold',
          )}
        >
          {t(`ratings.${thirdRow.text.boldText}`)}
          <span
            className={ivclass(
              'iv-inline-block',
              'iv-align-middle',
              'iv-ml-7',
              'iv-text-body-m sm:iv-text-body-xs',
              'iv-m-body-xl sm:iv-text-body-xs',
              'iv-font-bold',
            )}
          >
            {t(`ratings.${thirdRow.text.plainText}`)}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

const Achievement = (props: AchievementProps) => {
  const { firstRow, secondRow, thirdRow, CTAUrl = '', isLast } = props;
  return (
    (CTAUrl && (
      <a
        href={CTAUrl}
        className={ivclass(
          'iv-block',
          'sm:iv-mt-15 iv-mx-20 md:iv-mt-[13px] md:iv-mb-[34px] sm:iv-mb-[34px]',
          isLast ? 'sm:iv-mb-[20px]' : 'sm:iv-mb-[34px]',
        )}
      >
        <AchievementContent
          firstRow={firstRow}
          secondRow={secondRow}
          thirdRow={thirdRow}
        />
      </a>
    )) || (
      <div
        className={ivclass(
          'sm:iv-mt-15 iv-mx-20 md:iv-mt-[13px] md:iv-mb-[34px]',
          isLast ? 'sm:iv-mb-[20px]' : 'sm:iv-mb-[34px]',
        )}
      >
        <AchievementContent
          firstRow={firstRow}
          secondRow={secondRow}
          thirdRow={thirdRow}
        />
      </div>
    )
  );
};

export default Achievement;
